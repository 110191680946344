import React from 'react';
import styled from 'styled-components';

import { Regular } from 'components';

import BloodSugarChangeChartSvg from 'assets/images/summary-3/blood_sugar_graph.svg';
import { blue } from 'styles/colors';

export const BloodSugarChanges: React.FC = () => (
  <Wrapper>
    <Regular color={blue} lineHeight="1.5rem" margin="0 0 0.75rem 0">
      Your blood sugar trend is not positive <b>without Pulsio</b>
    </Regular>
    <BloodSugarChangeChartSvg width="100%" />
  </Wrapper>
);

export default BloodSugarChanges;

const Wrapper = styled.div`
  padding: 1rem;
  height: 100%;
  position: relative;
`;
