import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Container, GridWrapper, H1 } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

import ImprovementsInFirstMonth from '../elements/ImprovementsInFirstMonth';
import ReportedImprovements from '../elements/ReportedImprovements';
import StatisticsWithSameBloodPressure from '../elements/StatisticsWithSameBloodPressure';

import BloodPressureChangesStatic from '../elements/BloodPressureChanges';
import BloodPressureChangesDynamic from '../../summary-2/elements/BloodPressureChanges';
import BloodSugarChangesStatic from '../elements/BloodSugarChanges';
import BloodSugarChangesDynamic from '../../summary-2/elements/BloodSugarChanges';
// cholesterol
import CholesterolLevelChangesStatic from '../elements/CholesterolLevelChanges';
import CholesterolLevelChangesDynamic from '../../summary-2/elements/CholesterolLevelChanges';

import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface IGridItem {
  withShadow?: boolean;
  gridRow?: string | null;
  gridColumn?: string | null;
}

const SummaryData: React.FC = () => {
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((s: AppState) => s.user);
  const [layout, setLayout] = useState<'v2' | 'v3'>('v2');

  useEffect(() => {
    // Select grid bottom layout
    if (
      ['from_200_to_239', 'more_239'].find(a =>
        String(quiz_answers['cholesterol_level']).includes(a),
      ) ||
      ['yes'].find(a =>
        String(quiz_answers['high_cholesterol_history']).includes(a),
      )
    ) {
      setLayout('v2');
    } else {
      setLayout('v3');
    }
  }, [quiz_answers]);

  const cholesterolElement = useMemo(() => {
    if (
      ['from_200_to_239', 'more_239'].find(a =>
        String(quiz_answers['cholesterol_level']).includes(a),
      ) ||
      ['yes'].find(a =>
        String(quiz_answers['high_cholesterol_history']).includes(a),
      )
    ) {
      // Dynamic small one cholesterol from v2
      return <CholesterolLevelChangesDynamic />;
    } else {
      // Static big one from v3
      return <CholesterolLevelChangesStatic />;
    }
  }, [quiz_answers]);

  const bloodElement = useMemo(() => {
    if (
      ['less_120_and_less_80', 'from_120_to_129_and_less_80'].find(a =>
        String(quiz_answers['recently_measured_blood_pressure']).includes(a),
      )
    ) {
      return <BloodPressureChangesStatic />;
    } else {
      // dynamic v2
      return <BloodPressureChangesDynamic />;
    }
  }, [quiz_answers]);

  const sugarElement = useMemo(() => {
    if (String(quiz_answers['additional_symptoms']) === 'none_of_the_above') {
      return <BloodSugarChangesStatic />;
    } else {
      // dynamic v2
      return <BloodSugarChangesDynamic />;
    }
  }, [quiz_answers]);

  return (
    <Container
      maxWidth="48.75rem"
      padding="0 0 3rem"
      mobilePadding="0 1rem 2rem"
    >
      <H1
        fontSize="2.375rem"
        mobileFontSize="1.75rem"
        mobileLineHeight="2rem"
        margin={isMobile ? '1rem 0 0' : '1rem 0'}
        textAlign={isMobile ? 'left' : 'center'}
      >
        Your profile summary
      </H1>
      <GridWrapper
        columns={isMobile ? 1 : 2}
        gap={isMobile ? '1rem' : '2rem'}
        margin="0 0 2rem 0"
        alignItems="center"
      >
        <GridItem>
          <ImprovementsInFirstMonth />
        </GridItem>
        <GridItem>{bloodElement}</GridItem>
      </GridWrapper>

      <StyledGridWrapper
        columns={isMobile ? 1 : 2}
        gap={isMobile ? '1rem' : '2rem'}
      >
        <GridItem
          gridRow={isMobile ? '' : layout === 'v2' ? 'span 3' : '1 / span 5'}
          gridColumn={isMobile ? '1' : '1'}
          withShadow
        >
          {cholesterolElement}
        </GridItem>

        <GridItem
          gridRow={isMobile ? '' : '1 / span 4'}
          gridColumn={isMobile ? '1' : '2'}
          withShadow
        >
          {sugarElement}
        </GridItem>
        <GridItem
          gridRow={isMobile ? '' : layout === 'v2' ? 'span 3' : '5 / span 2'}
          gridColumn={isMobile ? '1' : layout === 'v2' ? '1' : '2'}
          withShadow
        >
          <StatisticsWithSameBloodPressure />
        </GridItem>
        <GridItem
          gridRow={isMobile ? '' : layout === 'v2' ? 'span 2' : 'auto'}
          gridColumn={isMobile ? '1' : layout === 'v2' ? '2' : '1'}
          withShadow
        >
          <ReportedImprovements />
        </GridItem>
      </StyledGridWrapper>
    </Container>
  );
};

export default SummaryData;

const StyledGridWrapper = styled(GridWrapper)`
  grid-template-rows: repeat(6, 1rf);

  @media ${mobile} {
    grid-auto-rows: unset;
  }
`;

const GridItem = styled.div<IGridItem>`
  background-color: transparent;
  box-shadow: ${props =>
    props.withShadow ? '0px 1rem 1rem rgba(8, 23, 62, 0.08)' : 'none'};
  grid-row: ${props => props.gridRow || 'auto'};
  border-radius: 1rem;
  grid-column: ${props => props.gridColumn || ''};
`;
