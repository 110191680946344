import styled from 'styled-components';

import { white } from 'styles/colors';

interface IProps {
  backgroundColor?: string;
  padding?: string;
  boxShadow?: string;
  margin?: string;
}

const Background = styled.div<IProps>`
  background-color: ${props => props.backgroundColor || white};
  padding: ${props => props.padding || 0};
  box-shadow: ${props => props.boxShadow || 0};
  margin: ${props => props.margin || 0};
`;

export default Background;
