import React from 'react';

import { PrivateRoute, Seo } from 'components';
import { useRouter } from 'apis/history';

import Header from './sections/Header';
import SummaryData from './sections/SummaryData';
import { Analytics } from 'apis/Analytics';
import UntreatedHighCholesterol from 'pages/summary-2/sections/UntreatedHighCholesterol';
import PersonalSummary from 'pages/summary-2/sections/PersonalSummary';
import LessThinking from 'pages/summary-2/sections/LessThinking';
import ManyLives from 'pages/summary-2/sections/ManyLives';
import OurPromise from 'pages/summary-2/sections/OurPromise';

const Summary3: React.FC = () => {
  const { goToCheckout } = useRouter();
  const handleCtaClick = () => {
    Analytics.trackCTAButton('summary3');
    goToCheckout();
  };

  return (
    <>
      <Seo title="Summary | Pulsio" />
      <PrivateRoute>
        <Header onCtaClick={handleCtaClick} />
        <SummaryData />
        <UntreatedHighCholesterol onCtaClick={handleCtaClick} />
        <PersonalSummary />
        <LessThinking onCtaClick={handleCtaClick} />
        <ManyLives />
        <OurPromise onCtaClick={handleCtaClick} />
      </PrivateRoute>
    </>
  );
};

export default Summary3;
