import { Regular } from 'components';
import React from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';

const ImprovementsInFirstMonth: React.FC = () => (
  <Wrapper>
    <Regular
      fontSize="1.625rem"
      lineHeight="2rem"
      mobileFontSize="1.25rem"
      mobileLineHeight="1.5rem"
      fontWeight="700"
    >
      Based on your answers you can improve your <RedText>heart</RedText> in{' '}
      <RedText>1st month</RedText>
    </Regular>
    <Regular
      margin="1rem 0 0 0"
      lineHeight="1.688rem"
      color="rgba(23, 22, 22, 0.7)"
    >
      We have evaluated your answers and prepared a summary based on{' '}
      <strong>11,497</strong> other profiles similar to yours.
    </Regular>
  </Wrapper>
);

export default ImprovementsInFirstMonth;

const Wrapper = styled.div`
  padding: 3.75rem 0;
  max-width: 22rem;
  @media ${mobile} {
    padding: 1rem 0;
  }
`;

const RedText = styled.span`
  color: #f06159;
`;
