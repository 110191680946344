import React from 'react';
import styled from 'styled-components';

import { Regular } from 'components';

import CholesterolWithSvg from 'assets/images/summary-3/cholesterol_with.svg';
import CholesterolWithoutSvg from 'assets/images/summary-3/cholesterol_without.svg';
import { blue } from 'styles/colors';

export const CholesterolLevelChanges: React.FC = () => (
  <Wrapper>
    <Regular
      color={blue}
      lineHeight="1.5rem"
      maxWidth="17rem"
      margin="0 0 0.75rem 0"
    >
      Your cholesterol level changes <b>without Pulsio</b>
    </Regular>
    <CholesterolWithoutSvg width="100%" />
    <Regular color={blue} lineHeight="1.5rem" margin="1.5rem 0 0.75rem 0">
      Your cholesterol level changes <b>with Pulsio</b>
    </Regular>
    <CholesterolWithSvg width="100%" />
  </Wrapper>
);

export default CholesterolLevelChanges;

const Wrapper = styled.div`
  padding: 1rem;
  height: 100%;
`;
