import React from 'react';
import styled from 'styled-components';

import { useQuery } from 'styles/breakpoints';
import { FlexWrapper, H1, Regular } from 'components';
import { blue } from 'styles/colors';

const ReportedImprovements: React.FC = () => {
  const { isMobile } = useQuery();
  return (
    <FlexWrapper
      alignItems="center"
      padding={isMobile ? '1.5rem 1rem' : '0 1rem'}
      height="100%"
      justifyContent="center"
      minHeight="5rem"
    >
      <StyledH1 fontSize="2.5rem" lineHeight="3.5rem">
        69%
      </StyledH1>
      <StyledRegular
        fontSize="0.813rem"
        lineHeight="1.125rem"
        color={blue}
        fontWeight="400"
        margin="0 0 0 1rem"
        mobileFontSize="0.8125rem"
        mobileLineHeight="1rem"
      >
        Reported improvements in waist area after the first week
      </StyledRegular>
    </FlexWrapper>
  );
};

export default ReportedImprovements;

const StyledH1 = styled(H1)`
  font-family: acumin-pro, sans-serif;
  color: #f06159;
`;

const StyledRegular = styled(Regular)`
  max-width: 13.75rem;
`;
