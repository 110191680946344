import React from 'react';
import styled from 'styled-components';

import GroupOfHumans from 'assets/icons/group_of_humans.svg';

import { FlexWrapper, Regular } from 'components';
import { blue, red } from 'styles/colors';

const StatisticsWithSameBloodPressure: React.FC = () => (
  <FlexWrapper
    alignItems="center"
    padding="2rem 1rem"
    height="100%"
    justifyContent="center"
    flexDirection="column"
  >
    <GroupOfHumans />
    <Regular margin="1rem 0 0" color={blue} fontWeight="600" textAlign="center">
      <RedHighlight>57%</RedHighlight> of our members have started with the same
      blood pressure level as you
    </Regular>
  </FlexWrapper>
);

export default StatisticsWithSameBloodPressure;

const RedHighlight = styled.span`
  color: ${red};
`;
