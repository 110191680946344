import React, { useMemo } from 'react';
import styled from 'styled-components';

import { blue, danger } from 'styles/colors';
import { FlexWrapper, Regular } from 'components';
import PressureWithoutGraphSvg from 'assets/images/summary-3/without_pulsio_graph.svg';
import PressureWithGraphSvg from 'assets/images/summary-3/with_pulsio_graph.svg';

const lowRiskColor = '#54B462';
const mediumRiskColor = '#FEC601';
const highRiskColor = '#FA3741';

export const BloodPressureChanges: React.FC = React.memo(() => {
  const renderGraphExplanation = useMemo(
    () => (
      <FlexWrapper justifyContent="space-between">
        <Regular color={blue} mobileFontSize="0.6875rem" fontSize="0.6875rem">
          Now
        </Regular>
        <Regular
          color={blue}
          mobileFontSize="0.6875rem"
          fontSize="0.6875rem"
          fontWeight="bold"
        >
          After 5-10 years
        </Regular>
      </FlexWrapper>
    ),
    [],
  );

  return (
    <WidgetWrapper>
      <Regular color={blue} margin="0 0 0.75rem 0">
        Your blood pressure trend <b>without Pulsio</b>
      </Regular>
      <PressureWithoutGraphSvg width="100%" />
      {renderGraphExplanation}
      <LegendWrapper
        margin="1rem 0 0"
        padding="0.25rem 0"
        justifyContent="space-evenly"
      >
        <LegendItem color={lowRiskColor}>
          <Regular color={blue} mobileFontSize="0.8125rem">
            Low risk
          </Regular>
        </LegendItem>
        <LegendItem color={mediumRiskColor}>
          <Regular color={blue} mobileFontSize="0.8125rem">
            Medium risk
          </Regular>
        </LegendItem>
        <LegendItem color={highRiskColor}>
          <Regular color={blue} mobileFontSize="0.8125rem">
            High risk
          </Regular>
        </LegendItem>
      </LegendWrapper>
      <Regular color={blue} margin="0.75rem 0">
        Your blood pressure trend <b>with Pulsio</b>
      </Regular>
      <PressureWithGraphSvg width="100%" />
      {renderGraphExplanation}
      <Disclaimer>
        <Regular
          lineHeight="1.25rem"
          fontSize="0.8125rem"
          mobileFontSize="0.8125rem"
          color={danger}
        >
          Based on your results, if no adjustments are made, in 4 to 5 years
          your risk of heart attack will rise to 84%, risk of stroke to 72%.
        </Regular>
      </Disclaimer>
    </WidgetWrapper>
  );
});

BloodPressureChanges.displayName = 'BloodPressureChanges';
export default BloodPressureChanges;

const WidgetWrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 16px 32px rgba(8, 23, 62, 0.08);
`;

const LegendWrapper = styled(FlexWrapper)`
  background: rgba(8, 23, 62, 0.04);
  border-radius: 8px;
`;

const LegendItem = styled(FlexWrapper)`
  align-items: center;
  justify-content: center;

  &:before {
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    margin-right: 0.5rem;
    background: ${props => props.color};
    border-radius: 50%;
  }
`;

const Disclaimer = styled.div`
  margin: 1rem 0 0;
  background: rgba(251, 54, 64, 0.1);
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
`;
