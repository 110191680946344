import React from 'react';
import styled from 'styled-components';

import { ComponentWithCtaEvent } from 'utils/interfaces';
import { Container, FlexWrapper, Logo, PrimaryButton } from 'components';
import { useQuery } from 'styles/breakpoints';

import Background from '../elements/Background';

const Header: React.FC<ComponentWithCtaEvent> = ({ onCtaClick }) => {
  const { isMobile } = useQuery();

  return (
    <Background
      padding="0.625rem 0"
      boxShadow="0px 8px 24px rgba(0, 0, 0, 0.04)"
    >
      <Container>
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <Logo notClickable centered={false} />
          <GetMyPlanButton
            padding={isMobile ? '0.688rem' : '0.938rem'}
            maxWidth={isMobile ? '8.125rem' : '10rem'}
            onClick={onCtaClick}
          >
            GET MY PLAN
          </GetMyPlanButton>
        </FlexWrapper>
      </Container>
    </Background>
  );
};

export default Header;

const GetMyPlanButton = styled(PrimaryButton)`
  font-weight: 700;
`;
